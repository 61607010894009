import Vue from 'vue'
import Router from 'vue-router'

import store from './store'
//Login機能
import Signup from '@/views/auth/signup'
import Loading from '@/views/auth/loading'
import AuthOver from '@/views/auth/authOver'

// import Signupok from "@/views/auth/signupok";
// import Info from "@/views/auth/info";
// import UserClassSelect from "@/views/auth/userClassSelect";
import NewPlayer from '@/views/auth/newPlayer'
import NewMaster from '@/views/auth/newMaster'
import NewCreator from '@/views/auth/newCreator'
import MasterCallManagement from '@/views/myPage/scenario/masterCallManagement.vue'
import ItemSynthTop from '@/views/world/itemSynth/itemSynthTop.vue'

//管理者用画面
const Admin = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/admin')

const WorldTweet = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldTweet')
const AdminNews = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/news/aNewsList')
const AdminNewsDetail = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/news/aNewsDetail')
const AdminOrderMade = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/aOrderMadeList')
const AdminReadyMade = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/aReadyMade')
const AdminReadyMadeSet = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/aReadyMadeSet')
const AdminOrderMadeUploader = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/admin/tailor/aOrderMadeUploader'
    )
const AdminOrderMadeUploaderDetail = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/admin/tailor/aOrderMadeUploaderDetail'
    )

const AdminConvertCheck = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/convertCheck')

const AdminOrderMadeDetail = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/admin/tailor/aOrderMadeDetail'
    )
const AdminFanLetter = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/admin/fanletter/aFanLetterList'
    )
const AdminFanLetterDetail = () =>
  import(
    /* webpackChunkName: "admin" */ '@/views/admin/fanletter/aFanLetterDetail'
    )
const AdminForm = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/form/freeFormList')
const AdminFormDetail = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/form/freeFormDetail')
const AdminObjectManagement = () =>
  import(/* webpackChunkName: "admin" */ '@/views/myPage/admin/adminObjectManagement')
const AdminNoteTagManagement = () =>
  import(/* webpackChunkName: "admin" */ '@/views/myPage/admin/adminNoteTag.vue')
// 2020/10/13 ADD START
const AdminaCreatorPoint = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/creator/aCreatorPoint')
// 2020/10/13 ADD END

// import KobetsuOptionSetup from "@/components/kobetsuOptionSetup"; //製造だけ使用
// import NoticeSetting from "@/components/NoticeSetting";
const WorldGallery = () => import('@/views/world/worldGallery')

const PacaShotList = () => import('@/views/world/photoshop/photoshopList')

const PacaShotBeta = () => import('@/views/world/photoshop/photoshopBata')

const PacaShotNameCard = () => import('@/views/world/photoshop/nameCard/pacaShotNameCard')

//管理者用画面
const CS = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/cs')

const CSTailor = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csOrderMadeList')

const CSTailorDetail = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csOrderMadeDetail')
const CSCharacter = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csCharacter')
const CSScenarioList = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csScenarioList')

const CSScenarioDetail = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csScenarioDetail')

const CSMail = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csMail')

const CSCreator = () =>
  import(/* webpackChunkName: "admin" */ '@/views/admin/tailor/csCreator')

//ポータル画面
const PortalTop = () =>
  import(/* webpackChunkName: "portal" */ '@/views/portalTop')
const PortalHeader = () =>
  import(/* webpackChunkName: "portal" */ '@/views/portalHeader')
const PortalFooter = () =>
  import(/* webpackChunkName: "portal" */ '@/views/portalFooter')
const PortalSearchResult = () =>
  import(
    /* webpackChunkName: "portal" */ '@/views/portalSearch/portalSearchResult'
    )

const AboutPBW = () =>
  import(/* webpackChunkName: "page" */ '@/views/aboutPBW')
const RecruitingForm = () =>
  import(/* webpackChunkName: "page" */ '@/views/recruitingForm')
const PastCampaign = () =>
  import(/* webpackChunkName: "page" */ '@/views/pastCampaign')

//お知らせ機能
const NewsList = () =>
  import(/* webpackChunkName: "news" */ '@/views/news/newsList')
const NewsDetail = () =>
  import(/* webpackChunkName: "news" */ '@/views/news/newsDetail')

//マスタールーム
const Master = () =>
  import(/* webpackChunkName: "master" */ '@/views/scenario/master')
const MasterHero = () =>
  import(/* webpackChunkName: "master" */ '@/views/scenario/masterHero')
const MasterBody = () =>
  import(/* webpackChunkName: "master" */ '@/views/scenario/masterBody')

const MasterCommunity = () =>
  import(
    /* webpackChunkName: "master" */ '@/views/masterCommunity/masterCommunity'
    )

const MasterCommunityChatRoom = () => import('@/components/MasterCommunity/MasterCommunityChatRoom')

const MasterCommunityPage = () =>
  import(
    /* webpackChunkName: "master" */ '@/views/masterCommunity/masterCommunityPage'
    )

const MasterCommunitySearchResult = () =>
  import(
    /* webpackChunkName: "master" */ '@/views/masterCommunity/masterCommunitySearchResult'
    )

const MasterCommunityAdmin = () =>
  import(
    /* webpackChunkName: "master" */ '@/views/masterCommunity/masterCommunityAdmin'
    )

const MasterCommunityMessageBoard = () =>
  import(
    /* webpackChunkName: "master" */ '@/views/masterCommunity/masterCommunityMessageBoard'
    )


//テーラー機能
const tailorMain = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/tailorMain')
const CreatorList = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/creatorList')
const Gallery = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/gallery')
const OrderMade = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/orderMade')
const TailorShareDetail = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/tailorShareDetail')
const ReadyMadeShop = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/readyMadeShop')
const ReadyMadeShopDetail = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/readyMadeShopDetail')
const OpenRequest = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/openRequest')
const OpenRequestCreateList = () =>
  import(
    /* webpackChunkName: "tailor" */ '@/views/tailor/openRequestCreateList'
    )

const OpenRequestListViewer = () =>
  import(
    /* webpackChunkName: "tailor" */ '@/views/tailor/openRequestListViewer'
    )

const HachuCreatorList = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/hachuCreatorList')
// const ReadyMadeGallery = () =>
//   import(/* webpackChunkName: "tailor" */ "@/views/tailor/readyMadeGallery");
const GalleryDetail = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/galleryDetail')

const ItemList = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/tailor/itemList')

//クリエイタールーム
const Creator = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creator')
const CreatorHero = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorHero')
const CreatorMenu = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorMenu')
const CreatorBody = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorBody')
const CreatorReadyMade = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorReadyMade')
const CreatorSample = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorSample')
const CreatorGallery = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorGallery')
const CreatorOrder = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/creatorOrder')
const GroupOrder = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/groupOrder')
const GroupOrderBody = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/groupOrderBody')
const GroupOrderMenu = () =>
  import(/* webpackChunkName: "creator" */ '@/views/tailor/groupOrderMenu')

const MyPage = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/myPage')

const MyPageMenu = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/myPageMenu')

const MyPageHome = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/home/myPageHome')


//マイページ > キャラクター
const MyPageCharacter = () =>
  import(
    /* webpackChunkName: "mypage" */ '@/views/myPage/character/myPageCharacter'
    )

//マイページ > 承認・運営
const MyPageAdminFreeItemList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/freeItemList'
    )

const MyPageAdminReadyMadeWorldCheck = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/readyMadeWorldCheck'
    );
//マイページ > 承認・運営
const MyPageAdminFreeCommunityAdmin = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/communityEventAdmin'
    )

const MyPageAdminFreeItemDetail = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/freeItemDetail'
    )

const MyPageAdminScenarioOpList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioOpList'
    )
const MyPageAdminScenarioOpListV2 = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioOpListV2'
    )

const MyPageAdminScenarioReList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioReList'
    )

const MypageAdminScenarioReact = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioReactListForAdmin'
    )

const MyPageAdminScenarioReactTemplateDetail = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/reactMasterTemplateDetail'
    )

const MypageAdminScenarioOmnibus = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioOmnibusListForAdmin'
    )

//マイページ > オムニバスシナリオ
const MyPageScenarioOmnibusDetailForAdmin = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioOmnibusDetailForAdmin'
    )
const MyPageAdminScenarioRequest = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioRequestListForAdmin'
    )
const MypageAdminScenarioRequestDetailForAdmin = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/scenarioRequestDetail'
    )
//マイページ > リプレイ一覧
const MyPageAdminScenarioReplayList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/replayListForAdmin'
    )

//マイページ > リプレイ個別
const MyPageAdminScenarioReplayDetail = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ "@/views/myPage/admin/replayDetailForAdmin"
  );

const MyPageAdminFixScenarioList = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/admin/fixScenarioList'
    )
const MyPageAdminFixScenarioDetail = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/admin/fixScenarioDetail'
    )
const MyPageAdminItemOriginalizeList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/itemOriginalizeList'
    )

const MyPageAdminSkillOriginalizeList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/skillOriginalizeList'
    )

const MyPageAdminMasterList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/masterList'
    )

const MyPageAdminReadyMade = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/readyMade'
    )

const MyPageAdminNpcList = () =>
  import(/* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/npcList')

const MyPageAdminWorldEventPageList = () =>
  import(/* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/worldEventPageList')

const MyPageAdminExcList = () =>
  import(/* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/excList')

const MyPageAdminNpcDetail = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/npcDetail'
    )

const MyPageAdminFreeFormList = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/freeFormList'
    )

const MyPageAdminFreeFormDetail = () =>
  import(
    /* webpackChunkName: "mypage-admin" */ '@/views/myPage/admin/freeFormDetail'
    )

//マイページ > テーラー
const MyPageTaylorJuchuList = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/juchuList'
    )

const MyPageTaylorJuchuDetail = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/juchuDetail'
    )

const MyPageTailorOrderMadeSetup = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/orderMadeSetup'
    )

const MyPageTailorCreatorRoom = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/creatorRoom'
    )

const MyPageReadyMadeSetup = () =>
  import(
    /* webpackChunkName: "creator" */ '@/views/myPage/tailor/readyMadeSetup'
    )

const MyPageReadyMade = () =>
  import(/* webpackChunkName: "creator" */ '@/views/myPage/tailor/readyMade')

const SelfOrderList = () =>
  import(
    /* webpackChunkName: "creator" */ '@/views/myPage/tailor/orderMade/selfOrder'
    )
const SelfOrderDetail = () =>
  import(
    /* webpackChunkName: "creator" */ '@/views/myPage/tailor/orderMade/selfOrderDetail'
    )

const MyPageTailorHachuList = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/tailor/hachuList')

const MyPageTailorSuggestList = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/tailor/suggestManage.vue')
const MyPageTaylorHachuDetail = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/hachuDetail'
    )

const MyPageTailorGroupOrderNpcList = () =>
  import(
    /* webpackChunkName: "mypage" */ '@/views/myPage/tailor/groupOrderNpcList'
    )

const MyPageTailorGroupOrderNpcDetail = () =>
  import(
    /* webpackChunkName: "creator" */ '@/views/myPage/tailor/groupOrderNpcDetail'
    )

const MyPageTaylorCallManagement = () =>
  import(
    /* webpackChunkName: "mypage-tailor" */ '@/views/myPage/tailor/tailorCallManagement'
    )
//マイページ > シナリオ
const MyPageScenarioList = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioList'
    )

//マイページ > オムニバスシナリオ
const MyPageScenarioOmnibusListForMaster = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioOmnibusListForMaster'
    )

//マイページ > オムニバスシナリオ
const MyPageScenarioOmnibusDetailForMaster = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioOmnibusDetailForMaster'
    )

//マイページ > リプレイ一覧
const MyPageScenarioReplayList = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/replayList'
    )
//マイページ > リプレイ一覧
const MyPageScenarioOpeningDetail = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/scenarioOpeningDetailForManagement'
    )

const ScenarioAppendForManagement = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/scenariAppendForManagement'
    )
//マイページ > リプレイ個別
const MyPageScenarioReplayDetail = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/replayDetail'
    )

const MyPageScenarioSetting = () =>
  import('@/views/myPage/scenario/scenarioSettingList')

//マイページ > シナリオ
const MyPageExcManagement = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/excManagement'
    )

//マイページ > ワールド
const MyPageScenarioWorldList = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/worldList'
    )

const MyPageScenarioMyPageSetup = () =>
  import(
    /* webpackChunkName: "mypage-masterMyPageSetup" */ '@/views/myPage/scenario/masterMyPageSetup'
    )

const MyPageScenarioInfo = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioInfo'
    )

const MyPageMasterCall = () =>
  import( '@/views/myPage/scenario/masterCallManagement')
const MyPageScenarioOpDetail = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioOp'
    )
const MyPageScenarioOpDetailV2 = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/admin/openingDetailForAdmin'
    )
const MyPageScenarioReDetail = () =>
  import(
    /* webpackChunkName: "mypage-scenario" */ '@/views/myPage/scenario/scenarioRe'
    )

//マイページ > コイン
const MyPageCoinPurchase = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/coin/coinPurchase')

const MyPageCoinHistory = () =>
  import(/* webpackChunkName: "mypage" */ '@/views/myPage/coin/coinHistory')

const MyPageCoinPurchaseStatus = () =>
  import(
    /* webpackChunkName: "mypage" */ "@/views/myPage/coin/coinPurchaseStatus"
  );

//マイページ > アカウント設定
const MyPageAccountUserClassSetup = () =>
  import(
    /* webpackChunkName: "mypage" */ '@/views/myPage/account/userClassSetup'
    )

const MyPageAccountUserInfoSetup = () =>
  import(
    /* webpackChunkName: "mypage" */ "@/views/myPage/account/userInfoSetup"
    )
//マイページ > 通知設定
const MyPageAccountNotificationsSetup = () =>
  import(
    /* webpackChunkName: "mypage" */ "@/views/myPage/account/userNotificationsSetup"
    );
const MyPageUserFollowCharacter = () =>
  import(
    /* webpackChunkName: "mypage" */ '@/views/myPage/account/userFollowCharacter'
    );
const MyPageUserMailHistory = () =>
  import(
    /* webpackChunkName: "mypage" */ '@/views/myPage/account/mailHistory.vue'
    );
const MyPageAlpacaDrive = () =>
  import(
    /* webpackChunkName: "mypage" */ "@/views/myPage/account/userAlpacaDrive.vue"
    )


//alparaDrive個別ページ
const alpacaDriveDetail = () =>
  import(/* webpackChunkName: "tailor" */ '@/views/alpacaDrive/alpacaDriveDetail')


//ワールド一覧
const WorldSelect = () => import('@/views/worldSelect')

//ソロジャーナル
const SoloJournal = () => import("@/views/soloJournal/soloJournal.vue");
const SoloJournalList = () => import("@/views/soloJournal/soloJournalList.vue");

//キャンペーン
const Campaign = () => import('@/views/cp/campaign')
const PortalForm = () => import('@/views/cp/portalForm')

//ワールド画面
const WorldContainer = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldContainer')
const WorldMain = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldMain')
const WorldEvent = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldEvent')

const WorldEventNew = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldEventNew')

const CommunityEvent = () => import('@/views/world/communityEvent')

const WorldForm = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/worldForm')
const CharacterRegistration = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/characterRegistration')
const CharacterProfileEdit = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/characterProfileEdit')
const WorldCharacterSearch = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/characterSearch')
const WorldScenarioSearch = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/scenarioSearch')
const WorldScenarioSearchV2 = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/scenarioSearchV2')
const WorldNoteSearch = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/noteSearch')


const Message = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/message')
const ScenarioContainer = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/scenarioContainer')
const ScenarioInvite = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/scenarioInvite')

const ScenarioRequestForAdmin = () => import(/* webpackChunkName: "world" */ '@/views/world/scenarioInviteForAdmin')

const ScenarioRequestForMaster = () => import(/* webpackChunkName: "world" */ '@/views/world/scenarioInviteForMaster')

const AdminTailorManagement = () => import("@/views/admin/tailor/aTailorManagement")
const AdminTailorManagementDetail = () => import("@/views/admin/tailor/aTailorManagementDetail")

const NovelDetail = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/novelDetail')
const TailorSettings = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/tailorSettings')
const TailorSettingsGallery = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/tailorSettingsGallery')
const SobiItemSettings = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/sobiItemSettings')
const SkillSettings = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/skillSettings')
const NotFound = () =>
  import(/* webpackChunkName: "world" */ '@/components/error/NotFound')
const WithoutLogin = () =>
  import(/* webpackChunkName: "world" */ '@/components/error/WithoutLogin')
const FriendPage = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/friendPage')
const CharacterNote = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/characterNote')
const NoteDetail = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/noteDetail')
const CommunitySingle = () =>
  import(/* webpackChunkName: "world" */ '@/components/CommunitySingle')

const MakaiPost = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/makaiPost/makaiPost')

const daSimpleHistory = () =>
  import(/* webpackChunkName: "world" */ '@/views/world/DaSimple/daSimpleHistory')

const DiscordWebhookChecker = () =>
  import('@/views/discordWebhookChecker')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    //meta.savedPositionが設定されているページは遷移時にスクロールしない
    if (savedPosition || to.meta.savedPosition || from.meta.savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },

  routes: [


    // *-------------------------*
    // 管理画面
    // *-------------------------*

    // 管理画面トップ
    {
      path: '/admin',
      name: 'admin',
      components: {
        default: Admin
      },
      beforeEnter: (to, from, next) => {
        const isNewsDetailPage = to.path.startsWith('/admin/news/') && to.params.postId;
        if (isNewsDetailPage && store.state.adminFlag !== '1') { // if user is not an admin and the path is /admin/news/:postId
          next({
            name: 'newsDetail',
            params: {postId: to.params.postId}
          });
        } else if (store.state.adminFlag === '0') {
          next('/');
        } else {
          next();
        }
      },
      children: [
        // 管理画面トップ > お知らせ
        {
          path: '/admin/news',
          name: 'aNews',
          components: {
            adminBody: AdminNews
          }
        },
        {
          path: '/admin/news/list/:pageNo',
          name: 'aNewsWithPager',
          components: {
            adminBody: AdminNews
          }
        },
        // 管理画面トップ > お知らせ　> お知らせ個別
        {
          path: 'news/:postId',
          name: 'aNewsDetail',
          components: {
            adminBody: AdminNewsDetail
          },
          props: { adminBody: true }
        },
        // 管理画面トップ > お知らせ　> 新規作成
        {
          path: '/admin/news/new',
          name: 'aNewsNew',
          components: {
            adminBody: AdminNewsDetail
          }
        },

        // 管理画面トップ > オーダーメイド　> オーダーメイドリスト
        {
          path: '/admin/ordermade',
          name: 'aOrderMade',
          components: {
            adminBody: AdminOrderMade
          },
          props: { adminBody: true }
        },
        // 管理画面トップ > オーダーメイド　> オーダーメイド個別
        {
          path: '/admin/ordermade/:shouhinId',
          name: 'aOrderMadeNewDetail',
          components: {
            adminBody: AdminOrderMadeDetail
          },
          props: { adminBody: true }
        },
        {
          path: '/admin/ordermade/:shouhinShubetsu/:shouhinId',
          name: 'aOrderMadeNewDetailEach',
          components: {
            adminBody: AdminOrderMadeDetail
          },
          props: { adminBody: true }
        },

        // 管理画面トップ > レディメイド
        {
          path: '/admin/readyMade',
          name: 'aReadyMade',
          components: {
            adminBody: AdminReadyMade
          },
          props: {adminBody: true}
        },

        // 管理画面トップ > レディメイドリスト紐づけ （ワールド別）
        {
          path: '/admin/readyMadeSet/:worldId',
          name: 'aReadyMadeSetWorld',
          components: {
            adminBody: AdminReadyMadeSet
          },
          props: {adminBody: true}
        },

        // 管理画面トップ > レディメイドリスト紐づけ
        {
          path: '/admin/readyMadeSet',
          name: 'aReadyMadeSet',
          components: {
            adminBody: AdminReadyMadeSet
          },
          props: {adminBody: true}
        },

        {
          path: '/admin/ordermadeUploader',
          name: 'aOrderMadeUploader',
          components: {
            adminBody: AdminOrderMadeUploader
          },
          props: { adminBody: true }
        },
        {
          path: '/admin/ordermadeUploader/:shouhinId',
          name: 'aOrderMadeUploaderDetail',
          components: {
            adminBody: AdminOrderMadeUploaderDetail
          },
          props: {adminBody: true}
        },

        // 管理画面トップ > コンバート
        {
          path: '/admin/convertCheck',
          name: 'aConvertCheck',
          components: {
            adminBody: AdminConvertCheck
          },
          props: {adminBody: true}
        },

        // 管理画面トップ > ファンレター　> ファンレターリスト
        {
          path: '/admin/fanletter',
          name: 'aFanLetter',
          components: {
            adminBody: AdminFanLetter
          },
          props: { adminBody: true }
        },
        // 管理画面トップ > ファンレター　> ファンレター個別
        {
          path: '/admin/fanletter/:postId',
          name: 'aFanLetterDetail',
          components: {
            adminBody: AdminFanLetterDetail
          },
          props: { adminBody: true }
        },

        // 管理画面トップ > ファンレター　> フォームリスト
        {
          path: '/admin/form',
          name: 'aForm',
          components: {
            adminBody: AdminForm
          },
          props: { adminBody: true }
        },
        // 管理画面トップ > フォーム　> フォーム個別
        {
          path: '/admin/form/:formId',
          name: 'aFormDetail',
          components: {
            adminBody: AdminFormDetail
          },
          props: { adminBody: true }
        },

        // 2020/10/13 ADD START
        // 管理画面トップ > クリエイターポイント　> クリエイターポイント
        {
          path: '/admin/creator',
          name: 'aCreatorPoint',
          components: {
            adminBody: AdminaCreatorPoint
          },
          props: {adminBody: true}
        },
        {
          path: '/admin/tailorManagement',
          name: 'aTailorManagement',
          components: {
            adminBody: AdminTailorManagement
          },
          props: {adminBody: true}
        },

        {
          path: '/admin/tailorManagement/:shouhinKbn',
          name: 'aTailorManagementDetail',
          components: {
            adminBody: AdminTailorManagementDetail
          },
          props: {adminBody: true}
        },
        {
          path: '/admin/object',
          name: 'aObject',
          components: {
            adminBody: AdminObjectManagement
          },
          props: {adminBody: true}
        },
        {
          path: '/admin/noteTag',
          name: 'aNoteTag',
          components: {
            adminBody: AdminNoteTagManagement
          },
          props: {adminBody: true}
        }
        // 2020/10/13 ADD END

        //オプションセットアップ？？
        // {
        //   path: "/admin/kobetsuOptionSetup",
        //   name: "kobetsuOptionSetup",
        //   components: {
        //     adminBody: KobetsuOptionSetup,
        //   },
        // },

        //通知設定？？
        // {
        //   path: "/admin/noticeSetting",
        //   name: "NoticeSetting",
        //   components: {
        //     adminBody: NoticeSetting,
        //   },
        // },
      ]
    },


    {
      path: '/cs',
      name: 'cs',
      components: {
        default: CS
      },
      beforeEnter: (to, from, next) => {
        if (store.state.adminFlag === '0') {
          next({
            path: '/'
          })
        } else next()
      },
      children: [
        {
          path: '/cs/csTailor',
          name: 'csTailor',
          components: {
            adminBody: CSTailor
          },
          props: {adminBody: true}
        },
        // 管理画面トップ > オーダーメイド　> オーダーメイド個別
        {
          path: '/cs/csTailor/:shouhinId',
          name: 'csTailorDetail',
          components: {
            adminBody: CSTailorDetail
          },
          props: {adminBody: true}
        },
        {
          path: '/cs/character',
          name: 'csCharacter',
          components: {
            adminBody: CSCharacter
          },
          props: {adminBody: true}
        },

        {
          path: '/cs/scenario',
          name: 'csScenario',
          components: {
            adminBody: CSScenarioList
          },
          props: {adminBody: true}
        },
        {
          path: '/cs/scenario/:worldId/:scenarioId/:phase',
          name: 'csScenarioDetail',
          components: {
            adminBody: CSScenarioDetail
          },
          props: {adminBody: true}
        },

        {
          path: "/cs/mail",
          name: "csMail",
          components: {
            adminBody: CSMail,
          },
          props: {adminBody: true}
        },
        {
          path: "/cs/creator",
          name: "csCreator",
          components: {
            adminBody: CSCreator
          }
        },
      ]
    },


    // *-------------------------*
    // ポータル画面
    // *-------------------------*

    // ポータルトップ
    {
      path: '/',
      name: 'portalTop',
      components: {
        default: PortalTop,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    // ログイン
    {
      path: '/login',
      name: 'signup',
      components: {
        default: Signup,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      meta: {
        noRequiresUser: true
      },
      // ログイン済みならポータルへ遷移
      beforeEnter(to, from, next) {
        if (store.state.userId != '') {
          next({
            path: '/'
          })
        } else next()
      }
      // meta: {
      //   reload: true,
      // },
    },
    // {
    //   path: "/signupok",
    //   name: "signupok",
    //   component: Signupok,
    //   meta: {
    //     noRequiresUser: true,
    //   },
    // },
    {
      path: '/authOver',
      name: 'authOver',
      components: {
        default: AuthOver,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      meta: {
        noRequiresUser: true
      }
    },
    // {
    //   path: "/info",
    //   name: "info",
    //   component: Info,
    //   meta: {
    //     noRequiresUser: true,
    //   },
    // },
    // {
    //   path: "/userClassSelect",
    //   name: "userClassSelect",
    //   component: UserClassSelect,
    //   meta: {
    //     noRequiresUser: true,
    //   },
    // },

    {
      path: '/newPlayer',
      name: 'newPlayer',
      components: {
        default: NewPlayer,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      meta: {
        noRequiresUser: true
      }
    },
    {
      path: '/newMaster',
      name: 'newMaster',
      components: {
        default: NewMaster,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      meta: {
        noRequiresUser: true
      }
    },
    {
      path: '/newCreator',
      name: 'newCreator',
      components: {
        default: NewCreator,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      meta: {
        noRequiresUser: true
      }
    },
    {
      path: '/loading',
      name: 'loading',
      component: Loading,
      meta: {
        noRequiresUser: true
      }
    },

    // お知らせ
    {
      path: '/aboutPBW',
      name: 'aboutPBW',
      components: {
        default: AboutPBW,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      }
    },
    // クリエイター・マスター募集
    {
      path: '/recruitingForm',
      name: 'recruitingForm',
      components: {
        default: RecruitingForm,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      }
    },
    // 過去のキャンペーン募集
    {
      path: '/pastCampaign',
      name: 'pastCampaign',
      components: {
        default: PastCampaign,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      }
    },

    // お知らせ
    {
      path: '/news',
      name: 'news',
      components: {
        default: NewsList,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          page: route.query.page
        })
      }
    },
    {
      path: '/news/:postId',
      name: 'newsDetail',
      components: {
        default: NewsDetail,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: { default: true }
    },

    // キャンペーン
    {
      path: '/cp/:cpId',
      name: 'campaign',
      components: {
        default: Campaign,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: { default: true }
    },

    // フォーム
    {
      path: '/form/:formId',
      name: 'portalForm',
      components: {
        default: PortalForm,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: { default: true }
    },
    //　アルパカテーラー
    {
      path: '/tailor',
      components: {
        default: tailorMain,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      children: [
        //　アルパカテーラートップ
        {
          path: '',
          name: 'tailor',
          components: {
            tailorBody: CreatorList
          }
        },
        //　アルパカテーラートップ > クリエイター
        {
          path: '/tailor/creator',
          name: 'creator',
          components: {
            tailorBody: CreatorList
          }
        },

        {
          path: '/tailor/share/:shouhinId/:officialOptionId/:suuryoSentakuId',
          name: 'tailorShareDetail',
          components: {
            tailorBody: TailorShareDetail
          }
        },
        //　アルパカテーラートップ > ギャラリー
        {
          path: '/tailor/gallery',
          name: 'gallery',
          components: {
            tailorBody: Gallery
          }
        },
        //　アルパカテーラートップ > オーダーメイド
        {
          path: '/tailor/ordermade',
          name: 'ordermade',
          components: {
            tailorBody: OrderMade
          }
        },

        // {
        //   path: "/tailor/readymade/:setId",
        //   name: "readymade-detail",
        //   components: {
        //     tailorBody: ReadyMade,
        //   },
        //   meta: { savedPosition: true },
        // },

        //　アルパカテーラートップ > レディメイド
        {
          path: '/tailor/readymade',
          name: 'readymade',
          components: {
            tailorBody: ReadyMadeShop
          }
        },

        //　アルパカテーラートップ > オープンリクエスト
        {
          path: '/tailor/openRequest',

          components: {
            tailorBody: OpenRequest
          },
          children: [
            {
              path: '',
              name: 'openRequest',
              components: {
                openRequestBody: OpenRequestCreateList
              }
            },
            {
              path: '/tailor/openRequestList',
              name: 'openRequestListViewer',
              components: {
                openRequestBody: OpenRequestListViewer
              },
              props: {
                openRequestBody: (route) => ({
                  itemKbn: '0'
                })
              }
            },
            {
              path: '/tailor/openRequestList/:itemKbn',
              name: 'openRequestListViewer-item',
              components: {
                openRequestBody: OpenRequestListViewer
              },
              props: {
                openRequestBody: (route) => ({
                  itemKbn: route.params.itemKbn
                })
              }
            }
          ]
        },

        //　アルパカテーラートップ > オーダーメイド > 商品別 受注クリエイターリスト（キーワードなし）
        {
          path: '/tailor/ordermade/:itemKbn',
          name: 'ordermade-item',
          components: {
            tailorBody: HachuCreatorList
          },
          props: {
            tailorBody: (route) => ({
              itemKbn: route.params.itemKbn,
              tags: route.query.tags,
              ocWorld: route.query.ocWorld,
              liked: route.query.liked
            }),
            portalFooter: (route) => ({
              fixed: true
            })
          }
        },
        //　アルパカテーラートップ > オーダーメイド > 商品別 受注クリエイターリスト（キーワードあり）
        {
          path: '/tailor/ordermade/:itemKbn/:keyword',
          name: 'ordermade-itemResult',
          components: {
            tailorBody: HachuCreatorList
          },
          props: {
            tailorBody: (route) => ({
              itemKbn: route.params.itemKbn,
              keyword: route.params.keyword,
              tags: route.query.tags,
              ocWorld: route.query.ocWorld,
              liked: route.query.liked
            }),
            portalFooter: (route) => ({
              fixed: true
            })
          }
        }
        //　アルパカテーラートップ > レディメイド
        // {
        //   path: "/tailor/readymade",
        //   name: "readymade",
        //   components: {
        //     tailorBody: ReadyMadeGallery,
        //   },
        // },
      ]
    },

    //　アルパカテーラートップ > レディメイド個別
    {
      path: '/tailor/readymade/:setId',
      name: 'readymade-detail',
      components: {
        default: ReadyMadeShopDetail,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          setId: route.params.setId
        })
      }
    },

    // ノベルリスト
    {
      path: '/novel/',
      name: 'novel',
      components: {
        default: ItemList,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: 'novel',
          tags: route.query.tags
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    //　ノベルリスト（キーワードあり）
    {
      path: '/novel/:keyword',
      name: 'novelResult',
      components: {
        default: ItemList,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: 'novel',
          keyword: route.params.keyword,
          tags: route.query.tags
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    // イラストリスト
    {
      path: '/illust/',
      name: 'illust',
      components: {
        default: ItemList,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: 'illust'
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    // ボイスリスト
    {
      path: '/voice/',
      name: 'voice',
      components: {
        default: ItemList,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: 'voice'
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    //  ポータル検索
    {
      path: '/search/:type/:keyword',
      name: 'portalSearchResult',
      components: {
        default: PortalSearchResult,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          keyword: route.params.keyword,
          type: route.params.type,
          tags: route.query.tags,
          creatorKbn: route.query.creatorKbn,
          opend: route.query.opend,
          ocWorld: route.query.ocWorld,
          ocItem: route.query.ocItem,
          liked: route.query.liked,
          s: route.query.s,
          cId: route.query.cId,
          worldId: route.query.worldId,
          creatorId: route.query.creatorId,
          shouhinKbn: route.query.shouhinKbn
        }),
        portalHeader: (route) => ({
          keyword: route.params.keyword,
          type: route.params.type,
          tags: route.query.tags,
          creatorKbn: route.query.creatorKbn,
          opend: route.query.opend,
          ocWorld: route.query.ocWorld,
          ocItem: route.query.ocItem,
          liked: route.query.liked,
          s: route.query.s,
          cId: route.query.cId,
          worldId: route.query.worldId,
          creatorId: route.query.creatorId,
          shouhinKbn: route.query.shouhinKbn
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },
    {
      path: '/search/:type/',
      name: 'portalSearchResult-noWord',
      components: {
        default: PortalSearchResult,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: route.params.type,
          tags: route.query.tags,
          creatorKbn: route.query.creatorKbn,
          opend: route.query.opend,
          ocWorld: route.query.ocWorld,
          ocItem: route.query.ocItem,
          liked: route.query.liked,
          s: route.query.s,
          cId: route.query.cId,
          worldId: route.query.worldId,
          creatorId: route.query.creatorId,
          shouhinKbn: route.query.shouhinKbn
        }),
        portalHeader: (route) => ({
          keyword: route.params.keyword,
          type: route.params.type,
          tags: route.query.tags,
          creatorKbn: route.query.creatorKbn,
          opend: route.query.opend,
          ocWorld: route.query.ocWorld,
          ocItem: route.query.ocItem,
          liked: route.query.liked,
          s: route.query.s,
          cId: route.query.cId,
          worldId: route.query.worldId,
          creatorId: route.query.creatorId,
          shouhinKbn: route.query.shouhinKbn
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },
    {
      path: '/search/',
      name: 'portalSearchResult-top',
      components: {
        default: PortalSearchResult,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          type: 'all'
        }),
        portalFooter: (route) => ({
          fixed: true
        })
      }
    },

    //　マスタールーム
    {
      path: '/master/:masterId?',
      components: {
        default: Master,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: true,
        masterHero: true,
        masterBody: true
      },
      children: [
        //　マスタールーム
        {
          path: '',
          name: 'master-detail',
          components: {
            masterHero: MasterHero,
            masterBody: MasterBody
          }
        }
      ]
    },

    //　クリエイタールーム
    {
      path: '/creator/:creatorId?',
      components: {
        default: Creator,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: (route) => ({
          creatorId: route.params.creatorId,
          shouhinKbn: route.query.item
        }),
        creatorHero: true,
        creatorMenu: true,
        creatorBody: true,
        portalFooter: (route) => ({
          fixed: true
        })
      },
      children: [
        //　クリエイタールーム
        {
          path: '',
          name: 'creator-detail',
          components: {
            creatorHero: CreatorHero,
            creatorMenu: CreatorMenu,
            creatorBody: CreatorBody
          }
        },

        //　クリエイタールーム > レディメイド
        {
          path: 'readymade',
          name: 'creator-readymade',
          components: {
            creatorHero: CreatorHero,
            creatorMenu: CreatorMenu,
            creatorBody: CreatorReadyMade
          },
          props: {
            creatorBody: () => ({
              type: 'readymade'
            })
          }
        },

        //　クリエイタールーム > サンプル
        {
          path: 'sample',
          name: 'creator-sample',
          components: {
            creatorHero: CreatorHero,
            creatorMenu: CreatorMenu,
            creatorBody: CreatorSample
          },
          props: {
            creatorBody: () => ({
              type: 'sample'
            })
          }
        },

        //　クリエイタールーム > ギャラリー
        {
          path: 'gallery',
          name: 'creator-gallery',
          components: {
            creatorHero: CreatorHero,
            creatorMenu: CreatorMenu,
            creatorBody: CreatorGallery
          },
          props: {
            creatorBody: () => ({
              type: 'gallery'
            })
          }
        },

        //　クリエイタールーム > オーダーメイド発注
        {
          path: 'order',
          name: 'order',
          components: {
            creatorHero: CreatorHero,
            creatorMenu: CreatorMenu,
            creatorOrder: CreatorOrder
          },
          props: {
            creatorOrder: (route) => ({
              shouhinKbn: route.query.item
            })
          }
        },

        {
          path: '*',
          name: 'other',
          // 旧クリエイター画面を転送
          beforeEnter: (to, from, next) => {
            let arr = to.path.split('/')
            const i = arr.indexOf('creator', 0)
            arr.splice(i + 1, 1)

            next({
              path: arr.join('/')
            })
            next()
          }
        }
      ]
    },

    // グループ発注
    {
      path: '/groupOrder/:subOrderUrl',
      components: {
        default: GroupOrder,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        default: true,
        creatorHero: true,
        groupOrderMenu: true,
        groupOrderBody: true
      },
      children: [
        //　グループ発注画面
        {
          path: '',
          name: 'gruop-order',
          components: {
            creatorHero: CreatorHero,
            groupOrderMenu: GroupOrderMenu,
            groupOrderBody: GroupOrderBody
          }
        }
      ]
    },

    //openTailorRequest（オープンテーラーリクエスト）
    {
      path: '/openTailorRequest/order',
      name: 'openTailorRequestOrder',
      components: {
        creatorOrder: CreatorOrder
      },
      props: {
        creatorOrder: (route) => ({
          shouhinKbn: route.query.item
        })
      },
      children: []
    },

    //　ギャラリー個別
    {
      path: '/gallery/:itemId',
      name: 'gallery-detail',
      components: {
        default: GalleryDetail,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      }
    },

    //　ワールド選択画面 TODO:ワールドに名称変更
    {
      path: '/worldSelect',
      name: 'worldSelect',
      components: {
        default: WorldSelect,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      default: (route) => ({
        portalFooter: (route) => ({
          fixed: true
        })
      })
    },

    //　ユーザーマイページ
    {
      path: '/myPage',
      components: {
        default: MyPage,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: {
        portalFooter: (route) => ({
          fixed: true
        }),
        default: (route) => ({
          mode: route.params.mode
          // pageID: route.params.pageID,
          // itemList: route.query.worldId,
        })
      },
      children: [
        //　マイページ > ホーム
        {
          path: '',
          name: 'myPage',
          components: {
            myPageBody: MyPageHome
          }
        },
        {
          path: 'home',
          name: 'myPage-home',
          components: {
            myPageBody: MyPageHome
          }
        },
        //　マイページ > 承認・運営
        {
          path: 'admin',
          name: 'myPage-admin',
          components: {
            myPageMenu: MyPageMenu
            // myPageBody: MyPageCharacter,
          }
        },
        {
          path: 'admin/scenarioReactManage',
          name: 'myPage-admin-scenarioReactManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MypageAdminScenarioReact
          }
        },

        {
          path: 'admin/scenarioReactManage/:worldId/create',
          name: 'myPage-admin-reactMasterTemplateDetail-create',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReactTemplateDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              mode: 'create'
            })
          }
        },
        {
          path: 'admin/scenarioReactManage/:worldId/detail/:templateId',
          name: 'myPage-admin-reactMasterTemplateDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReactTemplateDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              templateId: Number(route.params.templateId),
              mode: 'detail'
            })
          }
        },

        // オムニバス詳細
        {
          path: 'admin/scenarioOmnibusManage/:omnibusId',
          name: 'myPage-admin-scenarioOmnibusManageDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOmnibusDetailForAdmin
          }
        },

        // リクエストリスト
        {
          path: 'admin/scenarioOrderManage/request',
          name: 'myPage-admin-scenarioRequestManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioRequest
          }
        },
        // リクエスト詳細
        {
          path: 'admin/scenarioOrderManage/request/:worldId/:scenarioOderId',
          name: 'myPage-admin-scenarioRequestManageDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: ScenarioRequestForAdmin
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioOderId: route.params.scenarioOderId
            })
          }
        },

        {
          path: 'admin/scenarioOmnibusManage',
          name: 'myPage-admin-scenarioOmnibusManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MypageAdminScenarioOmnibus
          }
        },

        // リプレイ
        {
          path: 'admin/scenarioReplayManage/:worldId/:scenarioId/:phase',
          name: 'myPage-admin-scenarioReplayManageDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReplayDetail
          }
        },
        {
          path: 'admin/scenarioReplayManage',
          name: 'myPage-admin-scenarioReplayManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReplayList
          }
        },

        {
          path: 'admin/scenarioInfoManage',
          name: 'myPage-admin-scenarioInfoManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReplayList
          }
        },

        {
          path: 'admin/readyMade',
          name: 'myPage-admin-readyMadeWorldCheck',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminReadyMadeWorldCheck
          }
        },
        {
          path: 'admin/freeItem',
          name: 'myPage-admin-freeItem',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFreeItemList
          }
        },
        {
          path: 'admin/communityEvent',
          name: 'myPage-admin-communityEvent',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFreeCommunityAdmin
          }
        },
        {
          path: 'admin/freeItem/:characterId',
          name: 'myPage-admin-freeItemDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFreeItemDetail
          },
          props: {
            myPageBody: (route) => ({
              characterId: route.params.characterId
            })
          }
        },

        {
          path: 'admin/op',
          name: 'myPage-admin-op',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioOpList
          },
          props: {
            myPageBody: (route) => ({
              scenarioId: ''
            })
          }
        },

        {
          path: 'admin/op/v2',
          name: 'myPage-admin-op-V2',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioOpListV2
          },
          props: {
            myPageBody: (route) => ({})
          }
        },
        {
          path: 'admin/op/:worldId/:scenarioId',
          name: 'myPage-admin-opDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: ('0000000000' + route.params.scenarioId).slice(-10)
            })
          }
        },

        {
          path: 'admin/opening/:worldId/:scenarioId',
          name: 'myPage-admin-opDetailV2',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpDetailV2
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: route.params.scenarioId
            })
          }
        },

        {
          path: 'admin/opening/:worldId/:scenarioId/:phase',
          name: 'myPage-admin-opDetailV2-append',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: ScenarioAppendForManagement
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: route.params.scenarioId,
              phase: route.params.phase,
              screenType: 'admin'
            })
          }
        },
        {
          path: 'admin/re',
          name: 'myPage-admin-re',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminScenarioReList
          }
        },
        {
          path: 'admin/re/:worldId/:scenarioId',
          name: 'myPage-admin-reDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioReDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: ('0000000000' + route.params.scenarioId).slice(-10)
            })
          }
        },
        {
          path: 'admin/fixScenario',
          name: 'myPage-admin-fixScenario',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFixScenarioList
          }
        },
        {
          path: 'admin/fixScenario/:worldId/:scenarioId',
          name: 'myPage-admin-fixScenarioDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFixScenarioDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: ('0000000000' + route.params.scenarioId).slice(-10)
            })
          }
        },

        {
          path: 'admin/itemOriginalize',
          name: 'myPage-admin-itemOriginalize',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminItemOriginalizeList
          }
        },

        {
          path: 'admin/itemOriginalize/:itemId',
          name: 'myPage-admin-itemOriginalizeDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminItemOriginalizeList
          }
        },

        {
          path: 'admin/slillOriginalize',
          name: 'myPage-admin-skillOriginalize',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminSkillOriginalizeList
          }
        },
        {
          path: 'admin/skillOriginalize/:skillId',
          name: 'myPage-admin-skillOriginalizeDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminSkillOriginalizeList
          }
        },
        //admin/worldEventPageList
        {
          path: 'admin/worldEventPageList',
          name: 'myPage-admin-worldEventPageList',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminWorldEventPageList
          }
        },
        {
          path: 'admin/master',
          name: 'myPage-admin-master',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminMasterList
          }
        },
        // {
        //   path: "admin/readyMade/:worldId/:setId",
        //   name: "myPage-admin-readyMadeDetail",
        //   components: {
        //     myPageMenu: MyPageMenu,
        //     myPageBody: MyPageAdminReadyMadeDetail,
        //   },
        //   props: {
        //     myPageBody: (route) => ({
        //       worldId: route.params.worldId,
        //       setId: Number(route.params.setId),
        //     }),
        //   },
        // },
        // {
        //   path: "admin/readyMade/:worldId",
        //   name: "myPage-admin-readyMadeWorld",
        //   components: {
        //     myPageMenu: MyPageMenu,
        //     myPageBody: MyPageAdminReadyMade,
        //   },
        //   props: {
        //     myPageBody: (route) => ({
        //       worldId: route.params.worldId,
        //     }),
        //   },
        // },
        // {
        //   path: "admin/readyMade",
        //   name: "myPage-admin-readyMade",
        //   components: {
        //     myPageMenu: MyPageMenu,
        //     myPageBody: MyPageAdminReadyMade,
        //   },
        // },

        {
          path: 'admin/npc',
          name: 'myPage-admin-npc',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminNpcList
          }
        },
        {
          path: 'admin/exc',
          name: 'myPage-admin-exc',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminExcList
          }
        },

        {
          path: 'admin/npc/:characterId',
          name: 'myPage-admin-npcDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminNpcDetail
          },
          props: {
            myPageBody: (route) => ({
              characterId: route.params.characterId
            })
          }
        },

        {
          path: 'admin/freeForm',
          name: 'myPage-admin-freeForm',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFreeFormList
          }
        },
        {
          path: 'admin/freeForm/:formId',
          name: 'myPage-admin-freeFormDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAdminFreeFormDetail
          },
          props: {
            myPageBody: (route) => ({
              formId: route.params.formId
            })
          }
        },

        //　マイページ > キャラクター管理
        {
          path: 'character',
          name: 'myPage-character',
          components: {
            myPageBody: MyPageCharacter
          }
        },
        //　マイページ > テーラー管理
        {
          path: 'tailor',
          name: 'myPage-tailor',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorHachuList
          },
          //クリエイターは受注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag != '0') {
              next({
                name: 'myPage-tailor-myOrder'
              })
            } else next()
          }
        },
        {
          path: 'suggestManage',
          name: 'myPage-tailor-suggestManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorSuggestList
          },
        },

        {
          path: 'suggestManage/:shouhinId',
          name: 'myPage-tailor-suggestManage-orderDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTaylorHachuDetail
          },
          props: {
            myPageBody: (route) => ({
              shouhinId: Number(route.params.shouhinId),
              cId: route.query.cId
            })
          }
        },


        {
          path: 'tailor/tailorCall',
          name: 'myPage-tailor-tailorCall',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTaylorCallManagement
          }
        },
        {
          path: 'tailor/myOrder',
          name: 'myPage-tailor-myOrder',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTaylorJuchuList
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/openRequest',
          name: 'myPage-tailor-openRequest',
          //旧URLは発注リストへ
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-tailor-order'
            })
          }
        },


        {
          path: 'tailor/openRequest/:id',
          name: 'myPage-tailor-openRequestDetail',
          //旧URLは発注リストへ
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-tailor-order',
              params: {id: to.params.id}
            })
          }
        },

        {
          path: 'tailor/selfOrder',
          name: 'myPage-tailor-selfOrder',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: SelfOrderList
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/readyMadeSetup/:setId',
          name: 'myPage-tailor-readyMadeSetupDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageReadyMadeSetup
          },
          meta: {savedPosition: true},
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/readyMadeSetup',
          name: 'myPage-tailor-readyMadeSetup',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageReadyMadeSetup
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/selfOrderDetail/:shouhinKbn',
          name: 'myPage-tailor-selfOrderDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: SelfOrderDetail
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },
        {
          path: 'tailor/myOrder/:shouhinId',
          name: 'myPage-tailor-myOrderDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTaylorJuchuDetail
          },
          props: {
            myPageBody: (route) => ({
              shouhinId: Number(route.params.shouhinId)
            })
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        //旧受注ページの転送
        {
          path: 'tailor/8/:shouhinId',
          name: 'myPage-tailor-8',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-tailor-myOrderDetail',
              params: {shouhinId: to.params.shouhinId}
            })
          }
        },

        {
          path: 'tailor/ordermade',
          name: 'myPage-tailor-ordermade',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorOrderMadeSetup
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/creatorRoom',
          name: 'myPage-tailor-creatorRoom',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorCreatorRoom
          },
          //クリエイターの以外は発注リストへ
          beforeEnter: (to, from, next) => {
            if (store.state.creatorFlag === '0') {
              next({
                name: 'myPage-tailor'
              })
            } else next()
          }
        },

        {
          path: 'tailor/order/:shouhinId',
          name: 'myPage-tailor-orderDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTaylorHachuDetail
          },
          props: {
            myPageBody: (route) => ({
              shouhinId: Number(route.params.shouhinId),
              cId: route.query.cId
            })
          }
        },

        {
          path: 'tailor/order',
          name: 'myPage-tailor-order',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorHachuList
          },
          props: {
            myPageBody: (route) => ({
              shouhinId: Number(route.params.shouhinId),
              cId: route.query.cId
            })
          }
        },

        {
          path: 'tailor/groupOrderNpc/:shouhinId',
          name: 'myPage-tailor-groupOrderNpcDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorGroupOrderNpcDetail
          },
          props: {
            myPageBody: (route) => ({
              shouhinId: Number(route.params.shouhinId),
              cId: route.query.cId
            })
          }
        },

        {
          path: 'tailor/groupOrderNpc',
          name: 'myPage-tailor-groupNpc',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageTailorGroupOrderNpcList
          }
        },

        //旧発注ページの転送
        {
          path: 'tailor/13/:shouhinId',
          name: 'myPage-tailor-13',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-tailor-orderDetail',
              params: {shouhinId: to.params.shouhinId},
              query: {cId: to.query.cid}
            })
          }
        },

        {
          path: 'tailor/groupOrderNpc/detail/:shouhinId',
          name: 'myPage-tailor-groupOrderNpc-Detail',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-tailor-groupOrderNpcDetail',
              params: {shouhinId: to.params.shouhinId},
              query: {cId: to.query.cid}
            })
          }
        },

        // マイページ >レディメイド管理
        {
          path: 'tailor/readyMade/:setId/:ownedId',
          name: 'myPage-tailor-readyMadeDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageReadyMade
          },
          meta: {savedPosition: true}
        },

        {
          path: 'tailor/readyMade',
          name: 'myPage-tailor-readyMade',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageReadyMade
          }
        },
        //　マイページ > マスター管理
        {
          path: 'master',
          name: 'myPage-master',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody:MyPageMasterCall
          }
          //マスターは受注リストへ
          // beforeEnter: (to, from, next) => {
          //   if (store.state.masterFlag != "0") {
          //     next({
          //       name: "myPage-scenario-scenarioList",
          //     });
          //   } else next();
          // },
        },
        //　マイページ > マスター管理
        {
          path: 'master/masterCall',
          name: 'myPage-master-masterCall',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody:MyPageMasterCall
          }
          //マスターは受注リストへ
          // beforeEnter: (to, from, next) => {
          //   if (store.state.masterFlag != "0") {
          //     next({
          //       name: "myPage-scenario-scenarioList",
          //     });
          //   } else next();
          // },
        },
        //マイページ > シナリオ管理
        {
          path: 'scenario',
          name: 'myPage-scenario',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioInfo
          }
          //マスターは受注リストへ
          // beforeEnter: (to, from, next) => {
          //   if (store.state.masterFlag != "0") {
          //     next({
          //       name: "myPage-scenario-scenarioList",
          //     });
          //   } else next();
          // },
        },




        {
          path: 'scenario/op',
          name: 'myPage-scenario-opNew',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpDetail
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/scenarioSetting',
          name: 'myPage-scenario-scenarioSetting',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioSetting
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/scenarioList',
          name: 'myPage-scenario-scenarioList',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioList
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        // オムニバス詳細
        {
          path: 'scenario/omnibusDetail/:omnibusId',
          name: 'myPage-scenario-omnibusDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOmnibusDetailForMaster
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        // オムニバス新規
        {
          path: 'scenario/omnibusDetail',
          name: 'myPage-scenario-omnibusDetailNew',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOmnibusDetailForMaster
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/omnibusList',
          name: 'myPage-scenario-omnibusList',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOmnibusListForMaster
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/replayDetail/:worldId/:scenarioId/:phase',
          name: 'myPage-scenario-replayDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioReplayDetail
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        // opening詳細
        //MyPageScenarioOpeningDetail
        {
          path: 'scenario/openingDetail/:worldId/createOp',
          name: 'myPage-scenario-openingDetail-create',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpeningDetail
          },
          props: {
            screenType: 'create',
          }
        },
        {
          path: 'scenario/openingDetail/:worldId/:scenarioId/:phase',
          name: 'myPage-scenario-openingDetail-phaseManage',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: ScenarioAppendForManagement
          },
          props: {myPageBody: {screenType: 'addPhase'}}
        },

        {
          path: 'scenario/openingDetail/:worldId/:scenarioId',
          name: 'myPage-scenario-openingDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpeningDetail,
          },
          props: {
            screenType: 'edit',
            scenarioId: (route) => route.params.scenarioId,
          }
        },


        {
          path: 'scenario/replayList',
          name: 'myPage-scenario-replayList',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioReplayList
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/excManagement',
          name: 'myPage-scenario-excManagement',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageExcManagement
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },
        {
          path: 'scenario/masterRoom',
          name: 'myPage-scenario-masterRoom',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioMyPageSetup
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },

        {
          path: 'scenario/requestScenario/:orderId',
          name: 'myPage-scenario-scenarioRequestDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: ScenarioRequestForMaster
          },
          props: {
            myPageBody: (route) => ({
              scenarioOderId: route.params.orderId.toString(),
              mode: 'master'
            })
          }
        },
        {
          path: 'scenario/world',
          name: 'myPage-scenario-world',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioWorldList
          },
          //管理者はシナリオトップへ
          beforeEnter: (to, from, next) => {
            if (store.state.adminFlag == '1') {
              next({
                name: 'myPage-scenario'
              })
            } else next()
          }
        },
        {
          path: 'scenario/op/:worldId/:scenarioId',
          name: 'myPage-scenario-opDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioOpDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: ('0000000000' + route.params.scenarioId).slice(-10)
            })
          }
        },

        {
          path: 'scenario/re/:worldId/:scenarioId',
          name: 'myPage-scenario-reDetail',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioReDetail
          },
          props: {
            myPageBody: (route) => ({
              worldId: route.params.worldId,
              scenarioId: ('0000000000' + route.params.scenarioId).slice(-10)
            })
          }
        },

        {
          path: 'scenario/info',
          name: 'myPage-scenario-info',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageScenarioInfo
          }
        },

        //旧リプレイページの転送
        {
          path: 'scenario/op/:worldId',
          name: 'myPage-scenario-opDetail-old',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-scenario'
            })
          }
        },
        {
          path: 'scenario/24',
          name: 'myPage-scenario-24',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-scenario'
            })
          }
        },

        //　マイページ > コイン・ポイント
        {
          path: 'coin',
          name: 'myPage-coin',
          components: {
            myPageBody: MyPageCoinPurchase
          }
        },

        {
          path: 'coin/ac',
          name: 'myPage-coin-ac',
          components: {
            myPageBody: MyPageCoinHistory
          }
        },

        {
          path: 'coin/cp',
          name: 'myPage-coin-cp',
          components: {
            myPageBody: MyPageCoinHistory
          }
        },

        {
          path: 'coin/payment',
          name: 'myPage-coin-payment',
          components: {
            myPageBody: MyPageCoinHistory
          }
        },

        {
          path: 'coin/lp',
          name: 'myPage-coin-lp',
          components: {
            myPageBody: MyPageCoinHistory
          }
        },

        {
          path: 'coin/coinPurchaseStatus',
          name: 'myPage-coin-coinPurchaseStatus',
          components: {
            myPageBody: MyPageCoinPurchaseStatus
          }
        },

        //　マイページ > アカウント設定
        {
          path: 'account',
          name: 'myPage-account',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAccountUserInfoSetup
          }
        },

        {
          path: 'account/userInfo',
          name: 'myPage-account-userInfo',
          beforeEnter: (to, from, next) => {
            next({
              name: 'myPage-account'
            })
          }
        },

        {
          path: "account/mailHistory",
          name: "myPage-account-mailHistory",
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageUserMailHistory,
          },
        },
        {
          path: 'account/drive',
          name: 'myPage-account-drive',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAlpacaDrive
          }
        },
        {
          path: 'account/userClass',
          name: 'myPage-account-userClass',
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAccountUserClassSetup,
          },
        },

        {
          path: "account/notifications",
          name: "myPage-account-notifications",
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageAccountNotificationsSetup,
          },
        },

        {
          path: "account/follow",
          name: "myPage-account-follow",
          components: {
            myPageMenu: MyPageMenu,
            myPageBody: MyPageUserFollowCharacter,
          },
        },

      ],
    },

    // *-------------------------*
    // ワールド画面
    // *-------------------------*

    {
      path: '/world/:worldId',
      component: WorldContainer,
      props: (route) => ({
        cId: route.query.cId,
        worldId: route.params.worldId,

        //world検索用props
        worldSearchObject: {
          setSearchType: route.params.searchType,

          characterName: route.query.cn,
          characterKana: route.query.ck,
          characterAlias: route.query.ca,
          characterLevelMax: route.query.clmax,
          characterLevelMin: route.query.clmin,
          characterAgeMax: route.query.camax,
          characterAgeMin: route.query.camin,
          characterClassA: route.query.classA,
          characterClassB: route.query.classB,
          characterClassC: route.query.classC,
          characterSex: route.query.cs,
          characterType: route.query.ct,

          scenarioKeyword: route.query.sk,
          scenarioMasterName: route.query.sm,
          scenarioCheckBody: route.query.sc,
          scenarioScenarioType: route.query.ss,
          scenarioTags: route.query.st,
          scenarioDifficulty: route.query.sd,
          scenarioKanryoDateFrom: route.query.skdf,
          scenarioKanryoDateTo: route.query.skdt,
          scenarioSimekiriDateFrom: route.query.ssdf,
          scenarioSimekiriDateTo: route.query.ssdt,

          noteSearchWord: route.query.nk,
          noteFriendOnly: route.query.nf,
          noteTypeList: route.query.nt,
          noteShouhinId: route.query.nsh,
          noteScenarioId: route.query.nsc,
          noteCreatedDateFrom: route.query.ncdf,
          noteCreatedDateTo: route.query.ncdt,
          noteUpdatedDateFrom: route.query.nudf,
          noteUpdatedDateTo: route.query.nudt,
          noteTagId:Number(route.query.noteTagId),

          noteCharacterId: route.query.ncId,

          //scenarioV2用
          scenarioKeywordV2: route.query.sw,
          scenarioMasterNameV2: route.query.mi,
          scenarioCheckBodyV2: route.query.wc,
          scenarioScenarioTypeV2: route.query.st,
          scenarioTagsV2: route.query.st,
          scenarioDifficultyV2: route.query.dn,
          scenarioCompleteDateFromV2: route.query.cdtf,
          scenarioCompleteDateToV2: route.query.cdtt,
          scenarioOmnibusTemplateIdList: route.query.oi,
          scenarioReactTemplateIdList: route.query.ri
        }
      }),
      children: [
        // アイテム合成
        {
          path: '/world/:worldId/tweet',
          name: 'worldTweet',
          components: {
            worldBody: WorldTweet
          }
        },
        // アイテム合成
        {
          path: '/world/:worldId/itemSynth',
          name: 'itemSynth',
          components: {
            worldBody: ItemSynthTop
          }
        },
        // キャラクター登録
        {
          path: '/world/:worldId/characterRegistration',
          name: 'characterRegistration',
          components: {
            worldBody: CharacterRegistration
          }
        },

        {
          path: '',
          name: 'world',
          components: {
            worldBody: WorldMain
          },
          props: {
            menu: false
          }
        },

        // イベントページ
        {
          path: "/world/:worldId/makaiPost",
          name: "makaiPost",
          components: {
            worldBody: MakaiPost,
          },
          props: {worldBody: true},
        },
        // イベントページ
        {
          path: '/world/:worldId/event/:eventId',
          name: 'worldEvent',
          components: {
            worldBody: WorldEventNew
          },
          props: {worldBody: true}
        },

        // イベントページ
        {
          path: '/world/:worldId/eventNew/:eventId',
          name: 'worldEvent',
          components: {
            worldBody: WorldEventNew
          },
          props: {worldBody: true}
        },
        // フォーム
        {
          path: '/world/:worldId/form/:formId',
          name: 'worldForm',
          components: {
            worldBody: WorldForm
          },
          props: { worldBody: true }
        },
        // コミュニティイベントページ
        {
          path: '/world/:worldId/communityEvent/:communityEventId',
          name: 'communityEvent',
          components: {
            worldBody: CommunityEvent
          },
          props: {worldBody: true}
        },
        {
          path: '/world/:worldId/worldSearch/character',
          name: 'worldCharacterSearchResult',
          components: {
            worldBody: WorldCharacterSearch
          },
          props: {
            worldBody: (route) => ({
              worldSearchObject: {
                setSearchType: route.params.searchType,
                characterName: route.query.cn,
                characterKana: route.query.ck,
                characterAlias: route.query.ca,
                characterLevelMax: route.query.clmax,
                characterLevelMin: route.query.clmin,
                characterAgeMax: route.query.camax,
                characterAgeMin: route.query.camin,
                characterSex: route.query.cs,
                characterClassA: route.query.classA,
                characterClassB: route.query.classB,
                characterClassC: route.query.classC,
                characterType: route.query.ct,
                statusList: route.query.statusList
              }
            })
          }
        },
        {
          path: '/world/:worldId/worldSearch/scenario',
          name: 'WorldScenarioSearchResult',
          components: {
            worldBody: WorldScenarioSearch
          },

          props: {
            worldBody: (route) => ({
              worldSearchObject: {
                setSearchType: route.params.searchType,
                scenarioKeyword: route.query.sk,
                scenarioMasterName: route.query.sm,
                scenarioCheckBody: route.query.sc,
                scenarioScenarioType: route.query.ss,
                scenarioTags: route.query.st,
                scenarioDifficulty: route.query.sd,
                scenarioKanryoDateFrom: route.query.skdf,
                scenarioKanryoDateTo: route.query.skdt,
                scenarioSimekiriDateFrom: route.query.ssdf,
                scenarioSimekiriDateTo: route.query.ssdt
              }
            })
          }
        },

        {
          path: '/world/:worldId/worldSearch/scenarioV2',
          name: 'WorldScenarioSearchResultV2',
          components: {
            worldBody: WorldScenarioSearchV2
          },

          props: {
            worldBody: (route) => ({
              worldSearchObject: {
                scenarioKeyword: route.query.sw,
                scenarioMasterIdList: route.query.mi,
                scenarioCheckBody: route.query.wc,
                scenarioScenarioTypeList: route.query.st,
                scenarioDifficultyList: route.query.dn,
                scenarioCompleteDateFrom: route.query.cdtf,
                scenarioCompleteDateTo: route.query.cdtt,
                searchPage: route.query.searchPage,
                scenarioOmnibusTemplateIdList: route.query.oi,
                scenarioReactTemplateIdList: route.query.ri
              }
            })
          }
        },
        {
          path: '/world/:worldId/worldSearch/note',
          name: 'WorldNoteSearchResult',
          components: {
            worldBody: WorldNoteSearch
          },

          props: {
            worldBody: (route) => ({
              worldSearchObject: {
                setSearchType: route.params.searchType,
                noteSearchWord: route.query.nk,
                noteFriendOnly: route.query.nf,
                noteTypeList: route.query.nt,
                noteShouhinId: route.query.nsh,
                noteScenarioId: route.query.nsc,
                noteCreatedDateFrom: route.query.ncdf,
                noteCreatedDateTo: route.query.ncdt,
                noteUpdatedDateFrom: route.query.nudf,
                noteUpdatedDateTo: route.query.nudt,
                noteCharacterId: route.query.ncId,
                noteTagId:route.query.noteTagId
              }
            })
          }
        },

        // ワールド内検索
        {
          path: '/world/:worldId/worldSearch/:searchType',
          name: 'worldSearch',
          components: {
            worldBody: WorldCharacterSearch
          }
        },

        // メッセージ
        {
          path: '/world/:worldId/message',
          name: 'message',
          components: {
            worldBody: Message
          },
          meta: {
            requiresCharactor: true
          }
        },
        // キャラクター設定
        {
          path: '/world/:worldId/characterProfileEdit',
          name: 'characterProfileEdit',
          components: {
            worldBody: CharacterProfileEdit
          },
          meta: {
            requiresCharactor: true
          }
        },

        // テーラー設定
        {
          path: '/world/:worldId/tailorSettings',
          name: 'tailorSettings',
          components: {
            worldBody: TailorSettings
          },
          meta: {
            requiresCharactor: true
          }
        },
        // テーラー設定（ギャラリー）
        {
          path: '/world/:worldId/tailorSettings/gallery',
          name: 'tailorSettingsGallery',
          components: {
            worldBody: TailorSettingsGallery
          },
          meta: {
            requiresCharactor: true
          }
        },

        // アイテム・装備設定
        {
          path: '/world/:worldId/sobiItemSettings',
          name: 'sobiItemSettings',
          components: {
            worldBody: SobiItemSettings
          },
          meta: {
            requiresCharactor: true
          }
        },

        // スキル設定
        {
          path: '/world/:worldId/skillSettings',
          name: 'skillSettings',
          components: {
            worldBody: SkillSettings
          },
          meta: {
            requiresCharactor: true
          }
        },

        //　フレンド設定
        {
          path: '/world/:worldId/friendPage',
          name: 'friendPage',
          components: {
            worldBody: FriendPage
          },
          props: (route) => ({settab: route.query.tabs}),
          meta: {
            requiresCharactor: true
          }
        },

        //　ノート編集画面
        {
          path: '/world/:worldId/characterNote',
          name: 'characterNote',
          components: {
            worldBody: CharacterNote
          },
          meta: {
            requiresCharactor: true
          }
        },

        // ノート編集画面　> 新規作成
        {
          path: '/world/:worldId/characterNote/new',
          name: 'characterNoteNew',
          components: {
            worldBody: CharacterNote
          },
          props: {
            worldBody: (route) => ({
              noteId: ''
            })
          },
          meta: {
            requiresCharactor: true
          }
        },

        //　ノート編集画面　>　個別
        {
          path: '/world/:worldId/characterNote/:noteId',
          name: 'characterNoteEdit',
          components: {
            worldBody: CharacterNote
          },
          props: {
            worldBody: (route) => ({
              noteId: route.params.noteId
            })
          },
          meta: {
            requiresCharactor: true
          }
        },


        // ノート編集画面　> 新規作成
        {
          path: '/world/:worldId/characterNote/new',
          name: 'characterNoteNew',
          components: {
            worldBody: CharacterNote
          },
          props: {
            worldBody: (route) => ({
              noteId: ''
            })
          },
          meta: {
            requiresCharactor: true
          }
        },

        //　worldGallery
        {
          path: '/world/:worldId/worldGallery',
          name: 'worldGallery',
          components: {
            worldBody: WorldGallery
          },
          props: {},
        },
        //　パカショットリスト
        {
          path: '/world/:worldId/pacaShot',
          name: 'pacaShot',
          components: {
            worldBody: PacaShotList
          },
          props: {},
          meta: {
            requiresCharactor: true
          }
        },
        //　パカショットリスト
        {
          path: '/world/:worldId/pacaShotRoom/:roomId',
          name: 'pacaShotRoom',
          components: {
            worldBody: PacaShotBeta
          },
          props: {
            worldBody: (route) => ({
              roomId: route.params.roomId,
              hash: route.query.hash
            })
          },
          meta: {
            requiresCharactor: true
          }
        },
        //　パカショットリスト
        {
          path: '/world/:worldId/pacaShotNameCard',
          name: 'pacaShotNameCard',
          components: {
            worldBody: PacaShotNameCard
          },
          props: {
          },
          meta: {
            requiresCharactor: true
          }
        },
        // コミュニティ
        {
          path: '/world/:worldId/community/:communityId/:threadId',
          name: 'community-thread',
          components: {
            worldBody: CommunitySingle
          },
          props: {
            worldBody: (route) => ({
              communityId: route.params.communityId,
              threadId: route.params.threadId,
              worldId: route.params.worldId
            })
          }
        },

        {
          path: '/world/:worldId/community/:communityId',
          name: 'community',
          components: {
            worldBody: CommunitySingle
          },
          props: {
            worldBody: (route) => ({
              communityId: route.params.communityId,
              worldId: route.params.worldId
            })
          }
        },

        {
          path: '/world/:worldId/community/:communityId',
          name: 'community',
          components: {
            worldBody: CommunitySingle
          },
          props: {
            worldBody: (route) => ({
              communityId: route.params.communityId,
              worldId: route.params.worldId
            })
          }
        },


        // シナリオ
        {
          path: '/world/:worldId/scenario/:scenarioId/:phase',
          name: 'scenario',
          components: {
            worldBody: ScenarioContainer
          },
          props: { worldBody: true }
        },

        // シナリオ招待
        {
          path: '/world/:worldId/scenarioInvite/:scenarioOderId/:hash',
          name: 'scenarioInvite',
          components: {
            worldBody: ScenarioInvite
          },
          props: {worldBody: true, mode: 'invite'}
        },

        // ノベル
        {
          path: '/world/:worldId/novel/:novelId',
          name: 'worldNovel',
          components: {
            worldBody: NovelDetail
          }
        },

        // ノート
        {
          path: '/world/:worldId/note/:noteId',
          name: 'worldNote',
          components: {
            worldBody: NoteDetail
          },
          props: {
            worldBody: (route) => ({
              hs: route.query.hs,
              noteId: route.params.noteId
            })
          }
        },

        // ソロジャーナルプレイ画面
        {
          path: "/world/:worldId/soloJournal/:soloJournalId",
          name: "worldSoloJournal",
          components: {
            worldBody: SoloJournal, //なんかソロジャーナルのID
          },
          props: {
            worldBody: (route) => ({
              worldId: route.params.worldId,
              soloJournalId: route.params.soloJournalId,
            }),
          },
        },

        // ソロジャーナルプレイ画面
        {
          path: "/world/:worldId/soloJournal/:soloJournalId/playLog/:soloJournalPlayLogId",
          name: "worldSoloJournalPlayLog",
          components: {
            worldBody: SoloJournal, //なんかソロジャーナルのID
          },
          props: {
            worldBody: (route) => ({
              worldId: route.params.worldId,
              soloJournalId: route.params.soloJournalId,
              soloJournalPlayLogId: Number(route.params.soloJournalPlayLogId),
              hash: route.query.hash
            }),
          },
        },

        // ソロジャーナルリスト画面
        {
          path: "/world/:worldId/soloJournalList",
          name: "worldSoloJournalList",
          components: {
            worldBody: SoloJournalList, //なんかソロジャーナルのID
          },
          props: {
            worldBody: (route) => ({
              worldId: route.params.worldId,
            }),
          },
        },

        //　ワールドエラー画面（現在はキャラクター未選択の表示のみ）
        {
          path: '/world/:worldId/withoutLogin',
          name: 'worldWithoutLogin',
          components: {
            worldBody: WithoutLogin
          }
        },
        //DAシンプルバージョン履歴画面
        {
          path: '/world/:worldId/daSimpleHistory/:daTypeId',
          name: 'daSimpleHistory',
          components: {
            worldBody: daSimpleHistory
          },
          props: {
            worldBody: (route) => ({
              worldId: route.params.worldId,
              daTypeId: route.params.daTypeId
            })
          }
        },
      ]
    },

    {
      path: '/masterCommunity/:worldId',
      component: MasterCommunity,
      props: (route) => ({
        worldId: route.params.worldId
      }),
      children: [
        //　マスターコミュニティトップ
        {
          path: '',
          name: 'masterCommunityTop',
          components: {
            masterCommunityBody: MasterCommunityPage
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId
            })
          }
        },
        {
          path: '/masterCommunity/:worldId/page/:pageId',
          name: 'masterCommunityPage',
          components: {
            masterCommunityBody: MasterCommunityPage
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId,
              pageId: route.params.pageId
            })
          }
        },

        {
          path: '/masterCommunity/:worldId/search',
          name: 'masterCommunitySearch',
          components: {
            masterCommunityBody: MasterCommunitySearchResult
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId,
              searchWord: route.query.searchWord
            })
          }
        },
        {
          path: '/masterCommunity/:worldId/admin',
          name: 'masterCommunityAdmin',
          components: {
            masterCommunityBody: MasterCommunityAdmin
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId
            })
          }
        },
        {
          path: '/masterCommunity/:worldId/admin/:tabNo',
          name: 'masterCommunityAdminTab',
          components: {
            masterCommunityBody: MasterCommunityAdmin
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId,
              tabNo: route.params.tabNo
            })
          }
        },

        {
          path: '/masterCommunity/:worldId/chat',
          name: 'masterCommunityChatDefault',
          components: {
            masterCommunityBody: MasterCommunityChatRoom
          },
          props: {
            masterCommunityBody: (route) => ({
              roomId: route.params.worldId
            })
          }
        },
        {
          path: '/masterCommunity/:worldId/bbs/:boardId',
          name: 'masterCommunityBBS',
          components: {
            masterCommunityBody: MasterCommunityMessageBoard
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId,
              boardId: route.params.boardId
            })
          }
        },
        {
          path: '/masterCommunity/:worldId/bbs/:boardId/:threadId',
          name: 'masterCommunityBBSThread',
          components: {
            masterCommunityBody: MasterCommunityMessageBoard
          },
          props: {
            masterCommunityBody: (route) => ({
              worldId: route.params.worldId,
              boardId: route.params.boardId,
              threadId: route.params.threadId
            })
          }
        },
      ]
    },

    {
      path: '/alpacaDrive/:fileId',
      name: 'alpacaDriveDetail',
      components: {
        default: alpacaDriveDetail,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
      },
      props: { default: true }
    },
    // ディスコード通知先URLの承認画面
    {
      path: '/discordWebhookChecker/:hash',
      name: 'discordWebhookChecker',
      component: DiscordWebhookChecker,
      props: (route) => ({
        hash: route.params.hash
      })
    },

    // *-------------------------*
    // エラー表示
    // *-------------------------*
    {
      path: '/error/404',
      name: 'error404',
      components: {
        default: NotFound,
        portalHeader: PortalHeader,
        portalFooter: PortalFooter
        // default: NotFound,
      }
    },
    // パスが該当しない場合は404リダイレクト
    { path: '*', redirect: { name: 'error404' } }

  ]
})

router.beforeEach((to, from, next) => {
  //マイページのアクセス制限
  if (to.name.indexOf('myPage-admin') === 0 && store.state.adminFlag === '0') {
    next({ name: 'myPage' })
  }

  if (
    to.name.indexOf('myPage-scenario') === 0 &&
    store.state.adminFlag != '1' &&
    store.state.masterFlag != '1'
  ) {
    next({ name: 'myPage' })
  }

  // if (!to.matched.some((record) => record.meta.noRequiresUser)) {
  // このルートはキャラクター選択が必要です。
  // もしされていないならば、エラーページにリダイレクトします。
  //if (!store.state.userId) {
  //  next({
  //    path: "/"
  //  });
  //}
  // }
  if (to.matched.some((record) => record.meta.requiresCharactor)) {
    // このルートはキャラクター選択が必要です。
    // もしされていないならば、エラーページにリダイレクトします。
    if (!store.state.characterId) {
      next({
        path: '/withoutLogin',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }

  next() // next() を常に呼び出すようにしてください!
})

export default router
